import dayjs from 'dayjs';

import {
  // getUtcDate,
  // getDateFromUtc,
  getTodayWithTimeZone as getToday,
  getEndDateTime,
  getUTCTime,
  getUTCDateTime
} from '@utils/date';

const useTimeZone = () => {
  const baseTimeZone = 'Asia/Kolkata';

  const getUtcDateWithTimeZone = (date: Date) => {
    // const utcDate = getUtcDate(date, baseTimeZone);
    const utcDate = getUTCTime(date);
    return utcDate;
  };

  const getUtcDateTimeWithTimeZone = (date: Date) => {
    // const utcDate = getUtcDate(date, baseTimeZone);
    const utcDate = getUTCDateTime(date);
    return utcDate;
  };

  const getDateFromUtcDateWithTimeZone = (utcDate: string) => {
    // const date = getDateFromUtc(utcDate, baseTimeZone);
    const date = utcDate;
    return date;
  };

  const getTodayWithTimeZone = () => {
    return getToday(baseTimeZone);
  };

  const getDateWithTimeZone = (date: Date | number) => {
    return dayjs(date).tz(baseTimeZone);
  };

  const getUtcEndDateWithTimeZone = (date: Date) => {
    return getEndDateTime(date, baseTimeZone).utc().format();
  };

  return {
    baseTimeZone,
    getUtcDateWithTimeZone,
    getDateFromUtcDateWithTimeZone,
    getUtcDateTimeWithTimeZone,
    getTodayWithTimeZone,
    getDateWithTimeZone,
    getUtcEndDateWithTimeZone
  };
};

export default useTimeZone;
