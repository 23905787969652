import React, {
  FC,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { translate, translateDynamic } from '@utils/locale';
import { OpportunityStatus } from '@constants/common';
import {
  AnalyticsCard,
  CustomButton,
  MaterialReactTable,
  PersistingSearchBar
} from '@components';
import { FileDownload, GroupIcon, PencilEditIcon } from '@assets/icons';
import { Backdrop, Button, Chip, CircularProgress } from '@mui/material';
import { formatDateTime } from '@utils/common';
import { RoutePath } from '@routes/index';
import { Order } from '@components/custom-table/types';
import { useAppDispatch } from '@store/store';
import { USERS_TABS } from '@modules/users/user-details/containers/types';
import { RootState } from '@store/reducers';
import { colors } from '@constants/colors';
import { getFormattedDate } from '@utils/date';
import {
  icaifColorMapping,
  kycStatusColorMapping,
  tableHeaderMapper,
  wishlistedUsersTableHeader
} from '@modules/opportunities/create-wishlist/constants';
import { useLazyGetWishListedUsersQuery } from '@modules/opportunities/create-wishlist/api';
import { updateWishlistedUsersValues } from '@modules/opportunities/create-wishlist/reducer';
import {
  updateDownloadWishlistedUsersCompanyId,
  updateDownloadWishlistedUsersDealId
} from '@reducers/downloadWishlistedUsersReport';
import { opportunityColorMapping } from '@modules/opportunities/opportunities-list/constants';
import useTimeZone from '@hooks/useTimeZone';
import DealListEmptyState from '@modules/deals/deal-analytics/components/deal-list-empty-state/DealListEmptyState';
import DefaultColorChip from '@components/default-color-chip/DefaultColorChip';

const OverallWishlistedUsers: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { dealId, companyId } = useParams();

  const { getDateFromUtcDateWithTimeZone } = useTimeZone();

  const { search: searchText } = useSelector(
    (state: RootState) => state.rootReducer.wishlistedUsersReducer
  );

  const [limit, setLimit] = useState(25);
  const [offset, setOffset] = useState(0);
  const [sortOrder, setSortOrder] = useState<Order>('desc');
  const [sortBy, setSortBy] = useState<wishlistedUsersTableHeader>(
    wishlistedUsersTableHeader.WISHLISTED_DATE_TIME
  );

  const [getWishlistedUsers, { data: wishlistedUsers, isLoading, isFetching }] =
    useLazyGetWishListedUsersQuery();

  const { isLoading: reportIsLoading } = useSelector(
    (state: RootState) => state.rootReducer.downloadWishlistedUsersReport
  );

  const companyName = wishlistedUsers?.result?.data?.company_name;
  const status = wishlistedUsers?.result?.data?.deal_status;
  const endDate = wishlistedUsers?.result?.data?.expires_at;
  const startDate = wishlistedUsers?.result?.data?.start_time;
  const totalUsers = wishlistedUsers?.result?.data?.total_count ?? 0;

  const tableHeaders = [
    {
      id: 'name',
      label: translate('tableHeader.name'),
      showColumn: true,
      initialWidth: 150
    },
    {
      id: 'kycUsername',
      label: translate('tableHeader.kycName'),
      showColumn: true,
      initialWidth: 160
    },
    {
      id: 'email',
      label: translate('tableHeader.email'),
      showColumn: true,
      initialWidth: 200
    },
    {
      id: 'wishlistedDateTime',
      label: translate('tableHeader.wishlistedDateTime'),
      showColumn: true,
      initialWidth: 180
    },
    {
      id: 'residencyStatus',
      label: translate('tableHeader.residencyStatus'),
      showColumn: true,
      initialWidth: 130
    },
    {
      id: 'kycStatus',
      label: translate('tableHeader.kycStatus'),
      showColumn: true,
      initialWidth: 125
    },
    {
      id: 'icAifStatus',
      label: translate('tableHeader.icAifStatus'),
      showColumn: true,
      initialWidth: 170
    }
  ];

  const subscribedUsersData = useMemo(
    () =>
      wishlistedUsers?.result?.data?.wishlisted_user?.map(eachUser => ({
        id: eachUser.id,
        name: eachUser.name,
        email: eachUser.email,
        kycUsername: eachUser.kyc_username,
        residencyStatus:
          eachUser.residency_status === 'resident_indian' ? 'Indian' : 'NRI',
        kycStatus: eachUser.kyc_status,
        icAifStatus: eachUser.aif_status,
        wishlistedDateTime: formatDateTime(eachUser.date)
      })) || [],
    [wishlistedUsers?.result?.data]
  );

  const processTableData = (
    data: Record<string, string | number | boolean>
  ) => {
    return {
      name: (
        <div style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>
          {data.name || '-'}
        </div>
      ),
      kycUsername: (
        <div style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>
          {data.kycUsername || '-'}
        </div>
      ),
      email: (
        <div style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>
          {data.email || '-'}
        </div>
      ),
      residencyStatus: data.residencyStatus || '-',
      kycStatus: (
        <DefaultColorChip
          eachEntry={data.kycStatus}
          colorMapping={kycStatusColorMapping}
          labelValue={translateDynamic(`kycStatus.${data.kycStatus}`)}
          variant={'filled'}
        />
      ),
      icAifStatus: (
        <DefaultColorChip
          eachEntry={data.icAifStatus}
          colorMapping={icaifColorMapping}
          labelValue={translateDynamic(`icAifStatus.${data.icAifStatus}`)}
          variant={'filled'}
        />
      ),
      wishlistedDateTime: data.wishlistedDateTime || '-'
    };
  };

  const handleLimitChange = useCallback((newLimit: number) => {
    setLimit(newLimit);
  }, []);

  const handleOffsetChange = useCallback((newOffset: number) => {
    setOffset(newOffset);
  }, []);

  const handleChangeOrder = useCallback(
    (newOrder: Order) => {
      setSortOrder(newOrder);
      dispatch(
        updateWishlistedUsersValues({
          order: newOrder
        })
      );
    },
    [dispatch]
  );

  const handleSort = useCallback(
    (value: wishlistedUsersTableHeader) => {
      setSortBy(value);
      const sortByString =
        tableHeaderMapper[value.toString() as wishlistedUsersTableHeader];
      dispatch(
        updateWishlistedUsersValues({
          sortBy: sortByString
        })
      );
    },
    [dispatch]
  );

  const onClickDownloadReport = () => {
    dispatch(updateDownloadWishlistedUsersDealId(dealId));
    dispatch(updateDownloadWishlistedUsersCompanyId(companyId));
  };

  const handleViewUser = (
    data: Record<string, string | number | boolean | Date | null>
  ) => {
    navigate(
      `${RoutePath.VIEW_USER}/${data.id ?? ''}?tab=${USERS_TABS.DEAL_INFO}`
    );
  };

  const onClickNavigateButton = () => {
    navigate(`${RoutePath.VIEW_WISHLIST}/${dealId}/${companyId}`);
  };

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      getWishlistedUsers({
        dealId: dealId ?? '',
        searchTerm: encodeURIComponent(searchText),
        limit,
        offset,
        order: sortOrder,
        sortBy: sortBy
      });
    }, 500);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [searchText, sortBy, sortOrder, offset, limit]);

  return wishlistedUsers ? (
    <div className="py-6 px-5">
      <div className="flex justify-between items-center text-[34px] font-normal">
        <div className="flex items-center">
          {companyName
            ? translate('wishlistedUsers.header', { companyName })
            : translate('opportunities.viewWishlist')}
          {status && (
            <span className="ml-2">
              <div className="flex items-center ml-2">
                <DefaultColorChip
                  eachEntry={status ? status : OpportunityStatus.DRAFT}
                  colorMapping={opportunityColorMapping}
                  labelValue={translateDynamic(`dealStatus.${status}`)}
                  variant={
                    status === OpportunityStatus.ACTIVE ||
                    status === OpportunityStatus.EXPIRED ||
                    status === 'Approved'
                      ? 'filled'
                      : 'outlined'
                  }
                />
              </div>
            </span>
          )}
        </div>

        <div className="flex flex-row gap-5 justify-end items-center">
          {totalUsers > 0 && status !== OpportunityStatus.DRAFT ? (
            <Button
              variant="outlined"
              onClick={onClickDownloadReport}
              startIcon={
                !reportIsLoading && <FileDownload className="invisible" />
              }
              style={{
                color: 'black',
                border: '1px solid #212121',
                height: '36px',
                minWidth: '108px'
              }}
              className={`group py-3 px-4 w-[82px] h-[30px]  hover:!border-primary transition-opacity duration-700 1.25xl:h-[42px] ${
                reportIsLoading ? '!border-primary' : ''
              }`}
              disabled={reportIsLoading}>
              {reportIsLoading ? (
                <div className="flex absolute top-1/2 left-2 items-center space-x-2 -translate-y-1/2">
                  <CircularProgress size="1rem" sx={{ color: 'primary' }} />
                  <span className="text-primary">
                    {translate('opportunities.overallSubscriptionCsv')}
                  </span>
                </div>
              ) : (
                <>
                  <FileDownload className="absolute top-1/2 left-2 mr-4 -translate-y-1/2 group-hover:fill-primary" />
                  <span className="text-black group-hover:text-primary">
                    {translate('opportunities.overallSubscriptionCsv')}
                  </span>
                </>
              )}
            </Button>
          ) : (
            <></>
          )}
          <CustomButton
            label={'Edit'}
            onClick={onClickNavigateButton}
            variant="contained"
            fullWidth={false}
            customClassname="max-h-[36px]"
            icon={<PencilEditIcon className="fill-white" />}
          />
        </div>
      </div>
      <div className="flex flex-row gap-2 mt-5">
        <Chip
          label={translate('startDate', {
            date: getFormattedDate(
              getDateFromUtcDateWithTimeZone(startDate ?? ''),
              'YYYY MMM DD, hh:mm A'
            )
          })}
          variant="filled"
          style={{
            fontSize: '16px',
            fontWeight: '400',
            borderRadius: '4px',
            backgroundColor: colors.gray01
          }}
        />
        <Chip
          label={translate('endDate', {
            date: getFormattedDate(
              getDateFromUtcDateWithTimeZone(endDate ?? ''),
              'YYYY MMM DD, hh:mm A'
            )
          })}
          variant="filled"
          style={{
            fontSize: '16px',
            fontWeight: '400',
            borderRadius: '4px',
            backgroundColor: colors.gray01
          }}
        />
      </div>
      {status !== OpportunityStatus.DRAFT ? (
        <div className="flex flex-wrap gap-6 mt-5">
          <div className="flex flex-row gap-3 justify-between h-full">
            <AnalyticsCard
              title={totalUsers ?? '0'}
              subtitle={translate('wishlistedUsers.analytics.subtitle')}
              icon={<GroupIcon />}
              growth=""
              customStyle="flex-row w-full"
              customContainerStyle="!min-h-[106px] flex items-center "
              iconStyle="!bg-primaryGreen"
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      {totalUsers <= 0 || status === OpportunityStatus.DRAFT ? (
        <div className="p-9 h-full">
          <DealListEmptyState
            text={`${
              status === OpportunityStatus.DRAFT
                ? translate('wishlistedUsers.emptyState.notLive')
                : translate('wishlistedUsers.emptyState.noWishlistedUsers')
            }`}
            buttonLabel={translate(
              'wishlistedUsers.emptyState.editOpportunity'
            )}
            onClickNavigateButton={onClickNavigateButton}
          />
        </div>
      ) : (
        <>
          <div>
            <div className="pb-6 mt-4 text-2xl font-medium leading-7">
              {translate('wishlistedUsers.overallWishlistedUsers')}
            </div>
          </div>
          <div className="flex gap-4 pb-4 w-[70%] ">
            <div className="flex flex-row w-[50%] xl:w-[40%] 2xl:w-[30%]">
              <PersistingSearchBar
                placeholder={translate('search.placeholder')}
                label={translate('search.label')}
                dispatchFn={updateWishlistedUsersValues}
                defaultValue={''}
              />
            </div>
          </div>
          <Suspense fallback={<></>}>
            <MaterialReactTable
              tableHeaders={tableHeaders}
              tableData={subscribedUsersData}
              processTableData={processTableData}
              elevation={0}
              isLoading={isLoading || isFetching}
              page={Math.floor(offset / limit) ?? 0}
              rowsPerPage={limit ?? 0}
              handleLimitChange={handleLimitChange}
              handleOffsetChange={handleOffsetChange}
              autoResetPageIndex={false}
              order={sortOrder}
              handleChangeOrder={handleChangeOrder}
              handleOrderBy={handleSort}
              orderBy={sortBy}
              totalRows={wishlistedUsers?.result.total_count ?? 0}
              isRowClickable={true}
              handleRowClick={handleViewUser}
              customHeightOffest={155}
              disableColumnActionMenu
            />
          </Suspense>
        </>
      )}
    </div>
  ) : (
    <div className="flex flex-col p-9">
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={isLoading || isFetching}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default OverallWishlistedUsers;
