import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { handleDownloadFile } from '@utils/common';
import { ToastTypes } from '@type/toast';
import { translate } from '@utils/locale';
import { API_RESPONSE_STATUS } from '@constants/common';
import { RootState } from '@store/reducers';
import { useAppDispatch } from '@store/store';
import { showGenericErrorToast } from '@utils/error';
import {
  useLazyGenerateSubscribedReportPollQuery,
  useSubscribedUsersReportGenerationMutation
} from '@modules/opportunities/create-early-access/api';
import {
  updateDownloadSubscribedUsersDealId,
  updateDownloadSubscribedUsersIsLoading
} from '@reducers/downloadSubscribedUsersReport';
import displayToast from '@components/toast/customToast';

const DownloadSubscribedUsersReport = () => {
  const dispatch = useAppDispatch();
  const { dealId } = useSelector(
    (state: RootState) => state.rootReducer.downloadSubscribedUsersReport
  );

  const [pollingInterval, setPollingInterval] = useState<number>(3000);
  const [jobID, setJobId] = useState('');

  const [
    getAnalyticsReport,
    { data: analyticsReportResult, error: analyticsReportError }
  ] = useSubscribedUsersReportGenerationMutation();
  const [generateReportPoll, { data: pollStatus, error: pollStatusError }] =
    useLazyGenerateSubscribedReportPollQuery();

  const onGeneratePoll = (jobId: string) => {
    generateReportPoll({
      jobId: jobId
    });
  };

  const updateIsLoading = (isLoading: boolean) => {
    dispatch(updateDownloadSubscribedUsersIsLoading(isLoading));
  };

  useEffect(() => {
    if (dealId) {
      getAnalyticsReport({
        dealId: dealId,
        format: 'excel'
      });
      updateIsLoading(true);
      setPollingInterval(10000);
    }
  }, [dealId]);

  useEffect(() => {
    if (analyticsReportResult) {
      if (analyticsReportResult?.status === API_RESPONSE_STATUS.OK) {
        onGeneratePoll(analyticsReportResult.result.job_id);
        setJobId(analyticsReportResult.result.job_id);
      } else {
        updateIsLoading(false);
      }
    }
  }, [analyticsReportResult]);

  useEffect(() => {
    if (analyticsReportError || pollStatusError) {
      dispatch(updateDownloadSubscribedUsersDealId(''));
      updateIsLoading(false);
      setPollingInterval(0);
      setJobId('');
      displayToast(ToastTypes.ERROR, translate('errorFallbackMessage'));
    }
  }, [analyticsReportError, pollStatusError]);

  useEffect(() => {
    if (pollStatus && analyticsReportResult) {
      if (pollStatus?.result?.status === 'success') {
        displayToast(ToastTypes.SUCCESS, translate('report.success'));
        handleDownloadFile(pollStatus.result.signed_url);
        dispatch(updateDownloadSubscribedUsersDealId(''));
        updateIsLoading(false);
        setPollingInterval(10000);
        setJobId('');
      } else if (pollStatus?.result.status !== 'processing') {
        showGenericErrorToast(pollStatus?.error);
        dispatch(updateDownloadSubscribedUsersDealId(''));
        updateIsLoading(false);
        setPollingInterval(0);
        setJobId('');
      }
    }
  }, [pollStatus]);

  useEffect(() => {
    let interval: NodeJS.Timer;
    if (jobID && pollingInterval) {
      interval = setInterval(() => onGeneratePoll(jobID), pollingInterval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [jobID, pollingInterval]);

  return {};
};

export default DownloadSubscribedUsersReport;
