import { createSlice } from '@reduxjs/toolkit';

import { subscribedUsersReducerStateType } from './types';

const initialState = {
  search: '',
  offset: 0,
  limit: 25,
  order: 'desc',
  sortBy: '',
  clearPage: true
} as subscribedUsersReducerStateType;

export const subscribedUsersEarlyAccessSlice = createSlice({
  name: 'subscribedUsersEarlyAccess',
  initialState,
  reducers: {
    resetSubscribedUsersEarlyAccessValues: state => ({
      ...state,
      offset: 0,
      limit: 25,
      order: 'desc',
      sortBy: '',
      search: ''
    }),
    updateSubscribedUsersEarlyAccessValues: (state, { payload }) => ({
      ...state,
      ...payload
    })
  }
});

export const {
  resetSubscribedUsersEarlyAccessValues,
  updateSubscribedUsersEarlyAccessValues
} = subscribedUsersEarlyAccessSlice.actions;

export default subscribedUsersEarlyAccessSlice.reducer;
