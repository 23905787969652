export const colors = {
  primaryBlue: '#2196F3',
  white: '#ffffff',
  black: '#000000',
  gray88: '#E0E0E0',
  successGreen: '#14B72E',
  warningRed: '#D32F2F',
  alabaster: '#FAFAFA',
  oceanBlue: '#33D9B2',
  violet: '#6C5CE7',
  orange: '#EF6C00',
  gray: '#CCCCCC',
  purple: '#9C27B0',
  gray18: '#00000018',
  green: '#2E7D32',
  gray11: '#0000008a',
  black60: '#00000099',
  scarlet: '#C80000',
  cherryWood: '#5F2120',
  gray01: '#EEEEEE',
  gray02: '#666666',
  errorRed: '#FF5252',
  success300: '#1F826B',
  primaryGreen: '#E1FC6F',
  yellowOrange: '#EF6C00',
  darkBlue: '#009FBD',
  navyBlue: '#053B67',
  salem: '#099250',
  salem600: '#056235FF',
  mint: '#41B3A2',
  forestGreen: '#399918',
  deepPurple: '#522258',
  lightSkyBlue: '#AED2FF',
  steelBlue: '#366299',
  royalBlue: '#2F58CD',
  deepIndigo: '#27005D',
  mediumSeaGreen: '#3CCB7F',
  mediumSeaGreen600: '#28945AFF',
  magicMint: '#AAF0C4',
  magicMint600: '#08E95AFF',
  newGreen: '#4C8D64',
  lightCyan: '#A5F0FC',
  lightCyan600: '#3DD8F0FF',
  yellow: '#FFFF00',
  coralRed: '#f35d5e',
  pastelGreen: '#6ed16e',
  saffronMango: '#f5c745',
  darkYellow: '#D38609FF',
  gray600: '#9E9E9E',
  gray90: '#BCB9B9FF',
  teal: '#009688',
  skyBlue: '#03A9F4',
  deepBrown: '#795548',
  darkViolet: '#6A1B9A',
  indigoBlue: '#088AB2',
  aquaBlue: '#57BDD7',
  ochreLight: '#FFFCBA',
  ochreDark: '#FB8C7EFF',
  ochreMain: '#E3931AFF',
  amberYellow: '#FFEFE0',
  darkAmberYellow: '#D75A29',
  seaGreen: '#29ADB2',
  deepBlue: '#0E46A3',
  mediumPurple: '#8D6BD7FF',
  purpleHeart: '#5E35B8FF',
  ivoryCream: '#F1F0E8'
};
