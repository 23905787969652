import { colors } from '@constants/colors';
import { DealTypes } from '@constants/common';
import { hexToRgba } from '@utils/common';

export const tableHeaderMapper = {
  companyName: 'company_name',
  dealCode: 'company_deal_code',
  leadBy: 'syndicate_name',
  status: 'status',
  startDate: 'start_time',
  endDate: 'expires_at',
  dealType: 'type',
  investedStatus: 'investment_percentage',
  committedStatus: 'commitment_percentage'
};

export const dealTypeColorMapping = {
  [DealTypes.PRIVATE]: {
    backgroundColor: hexToRgba(colors.darkViolet, 0.1),
    textColor: colors.darkViolet
  },
  [DealTypes.PUBLIC]: {
    backgroundColor: hexToRgba(colors.skyBlue, 0.1),
    textColor: colors.skyBlue
  },
  [DealTypes.SYNDICATE]: {
    backgroundColor: hexToRgba(colors.teal, 0.1),
    textColor: colors.teal
  }
};
