import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';
import { FadeOptions, HighlightOptions } from '@mui/x-charts';
import useMediaQuery from '@mui/material/useMediaQuery';

import { AnalyticsCardRadiusValues } from '@constants/common';
import { colors } from '@constants/colors';
import EllipsisTooltipText from '@components/ellipsis-tooltip-text/EllipsisTooltipText';

import { AnalyticsCardType, RadiusValueType } from './types';

const StyledHeading = styled('text')(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fontWeight: 400,
  fontSize: 10
}));
const AnalyticsGraphCard = ({
  data,
  title,
  totalValue,
  totalText,
  disclaimer,
  valueFormatter,
  hideCenterLabel = false,
  cardName,
  allocationSize
}: AnalyticsCardType) => {
  const { width, height } = useDrawingArea();
  const isLargeScreen = useMediaQuery('(min-width:1280px)');
  const radiusValues: RadiusValueType = isLargeScreen
    ? AnalyticsCardRadiusValues
    : {
        1: [{ innerRadius: 40, outerRadius: 60 }],
        2: [
          { innerRadius: 40, outerRadius: 50 },
          { innerRadius: 32, outerRadius: 38 }
        ]
      };
  const series = data.map((val, index) => {
    return {
      data: val,
      ...radiusValues[data.length]?.[index],
      highlightScope: {
        faded: 'global' as FadeOptions,
        highlighted: 'item' as HighlightOptions
      },
      highlighted:
        data.length < 2 ? { innerRadius: 50, additionalRadius: 4 } : undefined,
      faded: { additionalRadius: 0 }
    };
  });

  return (
    <div
      className={`relative py-2 w-full max-w-[358px] rounded-xl border xl:p-3 xl:min-w-[362px] ${
        cardName === 'subscriptionStatus'
          ? 'xl:max-w-[510px]'
          : 'xl:max-w-[483px]'
      } xl:min-h-[180px]`}>
      <div className="flex justify-between">
        <div className="flex items-center h-full">
          <PieChart
            series={series}
            width={260}
            height={148}
            slotProps={{
              legend: { hidden: true }
            }}>
            {!hideCenterLabel && (
              <>
                <StyledHeading x={width / 4 - 18} y={height / 4 - 15}>
                  {totalText}
                </StyledHeading>
                <foreignObject
                  x={width / 4 - 57}
                  y={height / 4 - 10}
                  width="80"
                  height="24"
                  fontWeight={600}
                  fontSize={`${cardName === 'subscriptionStatus' ? 20 : 16}`}>
                  <EllipsisTooltipText
                    text={String(totalValue)}
                    className="flex justify-center max-h-[24px] whitespace-nowrap"
                  />
                </foreignObject>
              </>
            )}
          </PieChart>
        </div>
        <div className="flex flex-col ml-[-75px] w-full h-full text-base font-normal text-[#475467]">
          <div className="text-base font-medium leading-6 text-[#101828] 1.25xl:text-xl">
            {title}
          </div>
          <div className="flex flex-col gap-0.5 mt-1.5 mr-4 h-full 1.25xl:gap-2 1.25xl:mt-3">
            {data
              .flatMap(val => val)
              .map(item =>
                item.ignoreLegend ? (
                  <React.Fragment key={item.label}></React.Fragment>
                ) : (
                  <div
                    className="flex justify-between text-xs"
                    key={item.label}>
                    <div className="flex items-center mr-2 text-xs leading-5 1.25xl:text-sm">
                      <div
                        className="mr-1 w-2 h-2 text-sm rounded"
                        style={{ backgroundColor: item.color }}></div>
                      {item.label}
                    </div>
                    <div className="text-xs font-medium 1.25xl:text-sm">
                      {`${
                        valueFormatter
                          ? valueFormatter(item.value)
                          : item.value.toLocaleString()
                      }`}{' '}
                      {cardName === 'allocation' && (
                        <>
                          (
                          <span
                            style={{
                              color:
                                allocationSize &&
                                item.value &&
                                (item.value / allocationSize) * 100 > 100
                                  ? colors.coralRed
                                  : 'inherit'
                            }}>
                            {allocationSize && item.value
                              ? `${(
                                  (item.value / allocationSize) *
                                  100
                                ).toFixed(2)}%`
                              : '0%'}
                          </span>
                          )
                        </>
                      )}
                    </div>
                  </div>
                )
              )}
          </div>
        </div>
      </div>
      {disclaimer && (
        <div className="absolute bottom-3 left-3 mt-2 ml-3 text-[10px] font-normal text-[#999]">
          {disclaimer}
        </div>
      )}
    </div>
  );
};
export default AnalyticsGraphCard;
