import { colors } from '@constants/colors';
import { OpportunityStatus } from '@constants/common';
import { hexToRgba } from '@utils/common';

export const tableHeaderMapper = {
  companyName: 'company_name',
  oppCode: 'company_deal_code',
  status: 'status',
  subscriptionPercentage: 'subscription_percentage',
  wishlistCount: 'wishlist_count',
  startDate: 'start_time',
  endDate: 'expires_at'
};

export const opportunityColorMapping = {
  [OpportunityStatus.DRAFT]: {
    backgroundColor: colors.gray600,
    textColor: colors.gray600
  },
  [OpportunityStatus.ACTIVE]: {
    backgroundColor: hexToRgba(colors.pastelGreen, 0.2),
    textColor: colors.salem
  },
  [OpportunityStatus.EXPIRED]: {
    backgroundColor: hexToRgba(colors.coralRed, 0.15),
    textColor: colors.coralRed
  },
  [OpportunityStatus.CLOSED]: {
    backgroundColor: colors.purple,
    textColor: colors.purple
  },
  Approved: {
    backgroundColor: hexToRgba(colors.saffronMango, 0.15),
    textColor: colors.darkYellow
  }
};
