import { createSlice } from '@reduxjs/toolkit';

import { subscribedUsersReducerStateType } from '../create-early-access/types';

const initialState = {
  search: '',
  offset: 0,
  limit: 25,
  order: 'desc',
  sortBy: 'name',
  clearPage: true
} as subscribedUsersReducerStateType;

export const wishlistedUsersSlice = createSlice({
  name: 'wishlistedUsersEarlyAccess',
  initialState,
  reducers: {
    resetWishlistedUsersValues: state => ({
      ...state,
      offset: 0,
      limit: 25,
      order: 'desc',
      sortBy: 'name',
      search: ''
    }),
    updateWishlistedUsersValues: (state, { payload }) => ({
      ...state,
      ...payload
    })
  }
});

export const { resetWishlistedUsersValues, updateWishlistedUsersValues } =
  wishlistedUsersSlice.actions;

export default wishlistedUsersSlice.reducer;
