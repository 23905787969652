import React, { FC } from 'react';

import { EmptyState, PencilEditIcon } from '@assets/icons';
import { CustomButton } from '@components';

import { DealListEmptyStatePropsType } from './types';

const DealListEmptyState: FC<DealListEmptyStatePropsType> = ({
  text,
  buttonLabel,
  onClickNavigateButton
}) => {
  return (
    <div className="flex flex-col gap-[18px] justify-center items-center mt-32">
      <EmptyState />
      <div className="text-lg font-normal text-[#999999]">{text}</div>
      <CustomButton
        label={buttonLabel ?? ''}
        onClick={onClickNavigateButton}
        variant="outlined"
        fullWidth={false}
        customClassname="hover:!bg-primary hover:!text-white"
        icon={<PencilEditIcon className="group-hover:fill-white" />}
      />
    </div>
  );
};

export default DealListEmptyState;
