import { combineReducers } from 'redux';

import userAuth from '@reducers/userAuth';
import userListing from '@modules/users/user-listing/reducer';
import verifyUserListing from '@modules/kyc-approval/verify-user-listing/reducer';
import accessCodeListing from '@modules/invite/access-code/reducer';
import downloadAnalyticsReport from '@reducers/downloadAnalyticsReport';
import createDealStates from '@modules/deals/create-deal/reducer';
import dealListing from '@modules/deals/deals-list/reducer';
import syndicateListing from '@modules/syndicate/syndicate-list/reducer';
import breadCrumb from '@reducers/breadCrumb';
import opportunityListing from '@modules/opportunities/opportunities-list/reducers/opportunityList';
import companyListing from '@modules/companies/company-list/reducer';
import dealAnalytics from '@modules/deals/deal-analytics/reducer';
import unVerifiedDocsCount from '@reducers/unverifiedDocsCount';
import aifUnitPrice from '@modules/deals/deal-invite/reducer';
import screeningList from '@modules/screening/screening-list/reducers/screeningListReducer';
import screeningWaitlistedListReducer from '@modules/screening/screening-list/reducers/screeningWaitlistedReducer';
import screeninRejectedListReducer from '@modules/screening/screening-list/reducers/screeninRejectedListReducer';
import screeningPassListReducer from '@modules/screening/screening-list/reducers/screeningPassListReducer';
import screeningAllListReducer from '@modules/screening/screening-list/reducers/screeningAllListReducer';
import subscribedUsersEarlyAccess from '@modules/opportunities/create-early-access/reducer';
import wishlistedUsersReducer from '@modules/opportunities/create-wishlist/reducer';
import downloadSubscribedUsersReport from '@reducers/downloadSubscribedUsersReport';
import downloadWishlistedUsersReport from '@reducers/downloadWishlistedUsersReport';
import globalApiSupport from '@reducers/globalApiSupport';

import { store } from './store';

const rootReducer = combineReducers({
  userAuth,
  userListing,
  verifyUserListing,
  accessCodeListing,
  downloadAnalyticsReport,
  createDealStates,
  dealListing,
  syndicateListing,
  breadCrumb,
  opportunityListing,
  companyListing,
  dealAnalytics,
  unVerifiedDocsCount,
  aifUnitPrice,
  screeningList,
  screeninRejectedListReducer,
  screeningWaitlistedListReducer,
  screeningPassListReducer,
  screeningAllListReducer,
  subscribedUsersEarlyAccess,
  wishlistedUsersReducer,
  downloadSubscribedUsersReport,
  downloadWishlistedUsersReport,
  globalApiSupport
});

export type RootState = ReturnType<typeof store.getState>;

export default rootReducer;
