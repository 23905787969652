import { hexToRgba } from '@utils/common';
import { colors } from '@constants/colors';

import { CreateWishlistConfigurationValues } from './container/types';
import { CreateWishlistFormFieldValues } from './types';

export const createWishlistDefaultValues: CreateWishlistFormFieldValues = {
  id: '',
  wishlistId: '',
  companyId: '',
  dealCode: '',
  isPublish: false,
  isDraft: false,
  dealMode: '',
  status: 'Draft',
  showWishlistCount: true,
  wishlistTag: '',
  startDate: null,
  endDate: null,
  versionNumber: 0,
  // backgroundColor: '',
  valuationDealTerm: '',
  valuationDealTermId: ''
};

export const FIELD_TO_VALUE_MAPPER = {
  showWishlistCount: 'Wishlisted Count',
  wishlistTag: 'Wishlist Label',
  dealMode: 'Deal Mode',
  startDate: 'Start Date',
  endDate: 'End Date',
  valuationDealTerm: 'Valuation Deal Term',
  backgroundColor: 'Background Colour'
} as { [x: string]: string };

export const FIELD_TO_TAB_MAPPER = {
  0: ['companyId'],
  1: [],
  2: [
    'showWishlistCount',
    'wishlistTag',
    'dealMode',
    'startDate',
    'endDate',
    'valuationDealTerm',
    'backgroundColor'
  ]
} as { [x: string]: string[] };

export const wishlistConfigurationDefaultValues: CreateWishlistConfigurationValues =
  {
    showWishlistCount: true
  };

export const tableHeaderMapper = {
  name: 'name',
  email: 'email',
  kycUsername: 'kyc_username',
  residencyStatus: 'residency_status',
  kycStatus: 'kyc_status',
  icAifStatus: 'aif_status',
  wishlistedDateTime: 'date'
};

export enum wishlistedUsersTableHeader {
  NAME = 'name',
  EMAIL = 'email',
  KYC_USERNAME = 'kycUsername',
  RESIDENCY_STATUS = 'residencyStatus',
  KYC_STATUS = 'kycStatus',
  IC_AIF_STATUS = 'icAifStatus',
  WISHLISTED_DATE_TIME = 'wishlistedDateTime'
}

export const kycStatusColorMapping = {
  done: {
    backgroundColor: hexToRgba(colors.pastelGreen, 0.2),
    textColor: colors.salem
  },
  in_progress: {
    backgroundColor: hexToRgba(colors.primaryBlue, 0.15),
    textColor: colors.primaryBlue
  },
  not_started: {
    backgroundColor: hexToRgba(colors.saffronMango, 0.15),
    textColor: colors.darkYellow
  }
};

export const icaifColorMapping = {
  onboarded: {
    backgroundColor: hexToRgba(colors.pastelGreen, 0.2),
    textColor: colors.salem
  },
  not_onboarded: {
    backgroundColor: hexToRgba(colors.saffronMango, 0.15),
    textColor: colors.darkYellow
  }
};
