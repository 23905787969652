import React, { FC } from 'react';

import { formatNumber } from '@utils/common';
import { translate } from '@utils/locale';
import { colors } from '@constants/colors';

import { CircularProgressChartPropsType } from './types';
import CircularProgressRing from './CircularProgressRing';

const CircularProgressChart: FC<CircularProgressChartPropsType> = ({
  total,
  subscribed
}) => {
  const percentage =
    total > 0 && subscribed > 0 ? (subscribed / total) * 100 : 0;
  const isOversubscribed = percentage > 100;
  const displayPercentage = percentage.toFixed(2);
  return (
    <div className="relative py-2 w-full max-w-[358px] rounded-xl border xl:p-3 xl:min-w-[362px] xl:max-w-[483px] xl:min-h-[180px]">
      <div className="flex justify-between">
        {/* Circular progress ring */}
        <div className="flex items-center h-full">
          <CircularProgressRing
            label={translate(
              'opportunitySubscribedUsers.analytics.allocationSize'
            )}
            gradientColor={[colors.mediumPurple, colors.purpleHeart]}
            strokeWidth={15}
            radius={60}
            progress={subscribed}
            max={total}
          />
        </div>

        {/* Details Section */}
        <div className="flex flex-col pl-20 mt-2 ml-[-60px] w-full h-full text-base font-normal text-graphite">
          <div className="text-base font-medium leading-6 text-charcoalTeal 1.25xl:text-xl">
            {translate(
              'opportunitySubscribedUsers.analytics.subscriptionStatusTitle'
            )}
          </div>
          <div className="flex flex-col gap-0.5 mt-1.5 mr-4 h-full 1.25xl:gap-2 1.25xl:mt-3">
            <div className="flex justify-between text-xs">
              <div className="flex items-center  mr-2 text-xs leading-5 1.25xl:text-sm">
                <div
                  className="mr-1 w-2 h-2 text-sm rounded"
                  style={{ backgroundColor: colors.mediumPurple }}></div>
                {translate('opportunitySubscribedUsers.analytics.subscribed')}
              </div>
              <div className="text-xs font-medium 1.25xl:text-sm">
                {`${formatNumber(subscribed)}`} (
                <span
                  style={{
                    color: isOversubscribed ? colors.salem : 'inherit'
                  }}>
                  {total && subscribed ? `${displayPercentage}%` : '0%'}
                </span>
                )
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CircularProgressChart;
