import React, { FC, useState } from 'react';

import { formatNumber } from '@utils/common';
import { translate } from '@utils/locale';

import { CircularProgressRingPropsType } from './types';

const CircularProgressRing: FC<CircularProgressRingPropsType> = ({
  label,
  gradientColor,
  strokeWidth,
  radius,
  progress,
  max
}) => {
  const circumference = 2 * Math.PI * radius;

  // Percentage and offset of main progress
  const percentage = Math.min((progress / max) * 100, 94);
  const cappedPercentage = progress >= max ? 100 : percentage;
  const mainOffset = circumference - (cappedPercentage / 100) * circumference;

  // Overflow calculation
  const overflowProgress = progress >= max ? progress % max : -1;
  const overflowPercentage =
    overflowProgress >= 0 ? Math.min((overflowProgress / max) * 100, 93) : -1;
  const overflowOffset =
    circumference - (overflowPercentage / 100) * circumference;

  // Shadow center and angle
  const center = radius + strokeWidth;
  const finalAngle =
    overflowPercentage > 0
      ? (overflowPercentage / 100) * 360 - 90 + 3
      : cappedPercentage * 3.6 - 90 + 3;
  const finalAngle2 =
    overflowPercentage > 0
      ? (overflowPercentage / 100) * 360 - 90
      : cappedPercentage * 3.6 - 90;

  // Shadow position when overflow<0 and overflow>0
  const shadowX = center + radius * Math.cos(finalAngle * (Math.PI / 180));
  const shadowY = center + radius * Math.sin(finalAngle * (Math.PI / 180));

  // Shadow position when overflow === 0 (subscribed === round size)
  const shadowX2 = center + radius * Math.cos(finalAngle2 * (Math.PI / 180));
  const shadowY2 = center + radius * Math.sin(finalAngle2 * (Math.PI / 180));

  const [tooltipPosition, setTooltipPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    const { clientX, clientY } = e;
    setTooltipPosition({ x: clientX, y: clientY });
  };

  const handleMouseLeave = () => {
    setTooltipPosition(null);
  };

  return (
    <div
      className="flex relative flex-col items-center"
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}>
      <svg
        className="relative"
        height={(radius + strokeWidth) * 2}
        width={(radius + strokeWidth) * 2}>
        <defs>
          <linearGradient
            id="main-gradient"
            x1="100%"
            y1="100%"
            x2="0%"
            y2="100%">
            <stop offset="0%" stopColor={gradientColor[0]} />
            <stop offset="100%" stopColor={gradientColor[1]} />
          </linearGradient>
          <filter id="shadow-drop" x="-50%" y="-50%" width="190%" height="190%">
            <feDropShadow
              dx="0"
              dy="0"
              stdDeviation="3"
              floodColor="black"
              floodOpacity="0.8"
            />
          </filter>
        </defs>

        {/* Background circle */}
        <circle
          className="text-gray-300"
          strokeWidth={strokeWidth}
          stroke="currentColor"
          strokeLinecap="round"
          fill="transparent"
          r={radius}
          cx={center}
          cy={center}
        />
        {progress ? (
          <>
            {/* Shadow circle when only main progress present */}
            {overflowPercentage < 0 && (
              <circle
                cx={shadowX}
                cy={shadowY}
                r={strokeWidth / 2 - 3}
                fill={gradientColor[0]}
                filter="url(#shadow-drop)"
              />
            )}

            {/* Main progress circle */}
            <circle
              className="text-transparent"
              strokeWidth={strokeWidth}
              stroke={'url(#main-gradient)'}
              strokeLinecap="round"
              fill="transparent"
              r={radius}
              cx={center}
              cy={center}
              style={{
                strokeDasharray: circumference,
                strokeDashoffset: mainOffset,
                transform: 'rotate(-90deg)',
                transformOrigin: '50% 50%'
              }}
            />

            {/* Shadow circle when overflow occurs*/}
            {overflowPercentage >= 0 && percentage > 0 ? (
              <circle
                cx={shadowX}
                cy={shadowY}
                r={strokeWidth / 2 - 3}
                fill={gradientColor[0]}
                filter="url(#shadow-drop)"
              />
            ) : (
              <></>
            )}
            {/* Shadow circle when progress reaches top middle*/}
            {overflowPercentage === 0 && percentage > 0 && (
              <circle
                cx={shadowX2}
                cy={shadowY2}
                r={strokeWidth / 2}
                fill={gradientColor[0]}
              />
            )}

            {/* Overflow progress circle */}
            {overflowPercentage > 0 && (
              <circle
                className="text-transparent"
                strokeWidth={strokeWidth}
                stroke={gradientColor[0]}
                strokeLinecap="round"
                fill="transparent"
                r={radius}
                cx={center}
                cy={center}
                style={{
                  strokeDasharray: circumference,
                  strokeDashoffset: overflowOffset,
                  transform: 'rotate(-90deg)',
                  transformOrigin: '50% 50%'
                }}
              />
            )}
          </>
        ) : (
          <></>
        )}
      </svg>

      {/* center text */}
      <div className="absolute top-1/2 left-1/2 text-center -translate-x-1/2 -translate-y-1/2">
        <div className="text-[10px] font-normal">{label}</div>
        <div className="text-[16px] font-semibold">{formatNumber(max)}</div>
      </div>

      {/* Tooltip */}
      {tooltipPosition && (
        <div
          className="absolute py-2 px-4 bg-white border border-gray-200"
          style={{
            left: tooltipPosition.x - 200,
            top: tooltipPosition.y - 200,
            transform: 'translate(-50%, -50%)',
            whiteSpace: 'nowrap',
            width: 'auto',
            minWidth: '250px'
          }}>
          <div className="flex justify-between">
            <div className="flex items-center  mr-2  leading-5 text-gray-500">
              <div
                className="mr-1 w-2 h-2 rounded"
                style={{ backgroundColor: gradientColor[0] }}></div>
              {translate('opportunitySubscribedUsers.analytics.subscribed')}
            </div>
            <div>{`${formatNumber(progress)}`}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CircularProgressRing;
