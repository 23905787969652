import { colors } from '@constants/colors';
import { InvestmentStatusTable, UserStatus } from '@constants/common';
import { hexToRgba } from '@utils/common';

export enum DealAnalyticsTableHeader {
  NAME = 'name',
  KYC_USERNAME = 'kycUserName',
  EMAIL = 'email',
  PHONE = 'phone',
  INVESTOR_ROLE = 'investorRole',
  RESIDENT_STATUS = 'residentStatus',
  USER_STATUS = 'userStatus',
  USER_DETAILED_STATUS = 'userDetailedStatus',
  INVESTMENT_STATUS = 'investmentStatus',
  BANK_TYPE = 'bankAccountType',
  EXPECTED_COMMIT = 'expectedCommit',
  ACTUAL_COMMIT = 'actualCommit',
  AIF = 'aif',
  EXPECTED_INVESTMENT = 'expectedInvestment',
  INVESTED_AMOUNT = 'investedAmount',
  PENDING_INVESTMENT = 'pendingInvestment',
  EXISTING_INVESTOR = 'existingInvestor',
  DEAL_COMMITTED_DATE = 'dealCommittedDate',
  DEAL_INVITED_DATE = 'dealInvitedDate',
  ACTIONS = 'actions',
  DEMAT = 'dematDetails'
}

export enum AnalyticsUserStatus {
  YET_TO_SIGNUP = 'yetToSignUp',
  SIGNED_UP = 'signedUp',
  KYC_COMPLETED = 'kycCompleted',
  AIF_ONBOARDED = 'aifOnboarded'
}

export enum InvestmentStatus {
  ICF_SIGNED = 'icfSigned',
  COMMITTED = 'committed',
  NO_ENGAGEMENT = 'noEngagement',
  PAYMENT_COMPLETED = 'paymentCompleted',
  DROPPED_OFF = 'droppedOff'
}

export enum InvestorRole {
  FOUNDER_LED = 'founderLed',
  SYNDICATE_LED = 'syndicateLed',
  LP = 'lp',
  INVESTOR = 'investor'
}

export enum ActionTypes {
  inviteInvestors = 'inviteInvestor',
  editDeal = 'editDeal',
  changeState = 'changeState',
  report = 'report',
  download = 'download'
}
export const tableHeaderMapper = {
  name: 'user_name',
  kycUserName: 'kyc_user_name',
  email: 'email',
  phone: 'phone',
  investorRole: 'investor_role',
  investedAmount: 'invested_amount',
  investmentStatus: 'investment_status',
  pendingInvestment: 'pending_investment',
  actions: 'actions',
  dealCommittedDate: 'deal_committed_date',
  dealInvitedDate: 'deal_invited_date',
  dematDetails: 'demat_details',
  bankAccountType: 'bankAccountType',
  userDetailedStatus: 'detailed_user_status',
  userStatus: 'user_status',
  actualCommit: 'actual_commitment',
  aif: 'aif_fee',
  residentStatus: 'resident_status',
  expectedCommit: 'expected_commitment',
  expectedInvestment: 'expected_investment',
  existingInvestor: 'existing_investor'
};

export const dealUserStatusColorMapping = {
  [UserStatus.YET_TO_SIGN_UP]: {
    backgroundColor: colors.gray600,
    textColor: colors.gray600
  },
  [UserStatus.AIF_ONBOARDED]: {
    backgroundColor: hexToRgba(colors.salem, 0.4),
    textColor: colors.salem600
  },
  [UserStatus.SIGNED_UP]: {
    backgroundColor: colors.successGreen,
    textColor: colors.successGreen
  },
  [UserStatus.KYC_COMPLETED]: {
    backgroundColor: hexToRgba(colors.pastelGreen, 0.2),
    textColor: colors.salem
  }
};

export const investmentStatusColorMapping = {
  [InvestmentStatusTable.NO_ENGAGEMENT]: {
    backgroundColor: colors.gray600,
    textColor: colors.gray600
  },
  [InvestmentStatusTable.COMMITTED]: {
    backgroundColor: colors.lightCyan,
    textColor: colors.lightCyan600
  },
  [InvestmentStatusTable.ICF_SIGNED]: {
    backgroundColor: hexToRgba(colors.aquaBlue, 0.15),
    textColor: colors.aquaBlue
  },
  [InvestmentStatusTable.DRAWDOWN_SENT]: {
    backgroundColor: hexToRgba(colors.aquaBlue, 0.15),
    textColor: colors.aquaBlue
  },
  [InvestmentStatusTable.INVESTMENT_COMPLETED]: {
    backgroundColor: hexToRgba(colors.indigoBlue, 0.2),
    textColor: colors.indigoBlue
  },
  [InvestmentStatusTable.DROPPED_OFF]: {
    backgroundColor: hexToRgba(colors.orange, 0.2),
    textColor: colors.orange
  }
};
