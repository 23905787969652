import React, {
  FC,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { translate, translateDynamic } from '@utils/locale';
import { OpportunityStatus } from '@constants/common';
import {
  CustomButton,
  MaterialReactTable,
  PersistingSearchBar
} from '@components';
import { FileDownload, PencilEditIcon } from '@assets/icons';
import { Backdrop, Button, Chip, CircularProgress } from '@mui/material';
import { formatDateTime, formatToIndianRupee } from '@utils/common';
import { RoutePath } from '@routes/index';
import { Order } from '@components/custom-table/types';
import { useAppDispatch } from '@store/store';
import { USERS_TABS } from '@modules/users/user-details/containers/types';
import { RootState } from '@store/reducers';
import { colors } from '@constants/colors';
import { getFormattedDate } from '@utils/date';
import {
  updateDownloadSubscribedUsersCompanyId,
  updateDownloadSubscribedUsersDealId
} from '@reducers/downloadSubscribedUsersReport';
import {
  icaifColorMapping,
  kycStatusColorMapping
} from '@modules/opportunities/create-wishlist/constants';
import { opportunityColorMapping } from '@modules/opportunities/opportunities-list/constants';
import useTimeZone from '@hooks/useTimeZone';
import DealListEmptyState from '@modules/deals/deal-analytics/components/deal-list-empty-state/DealListEmptyState';
import CircularProgressChart from '@components/circular-progress-chart/CircularProgressChart';
import DefaultColorChip from '@components/default-color-chip/DefaultColorChip';

import { useLazyGetSubscribedUsersQuery } from '../../api';
import { updateSubscribedUsersEarlyAccessValues } from '../../reducer';
import {
  opportunityStatusSubscriptionColorMapping,
  subscribedUsersEarlyAccessTableHeader,
  tableHeaderMapper
} from '../../constants';
import SubscriptionStatusAnalytics from '../../components/subscription-status-analytics/SubscriptionStatusAnalytics';

const OverallSubscriptionStatus: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { dealId, companyId } = useParams();

  const { getDateFromUtcDateWithTimeZone } = useTimeZone();

  const { search: searchText } = useSelector(
    (state: RootState) => state.rootReducer.subscribedUsersEarlyAccess
  );
  const { isLoading: reportIsLoading } = useSelector(
    (state: RootState) => state.rootReducer.downloadSubscribedUsersReport
  );

  const [limit, setLimit] = useState(25);
  const [offset, setOffset] = useState(0);
  const [sortOrder, setSortOrder] = useState<Order>('desc');
  const [sortBy, setSortBy] = useState<subscribedUsersEarlyAccessTableHeader>(
    subscribedUsersEarlyAccessTableHeader.SUBSCRIPTION_DATE_TIME
  );

  //API: overall subscribed user list and opportunity details
  const [getSubscribedUsers, { data: subscribedUsers, isLoading, isFetching }] =
    useLazyGetSubscribedUsersQuery();

  //Details of corresponding opportunity
  const companyName = subscribedUsers?.result?.data?.company_name;
  const status = subscribedUsers?.result?.data?.deal_status;
  const endDate = subscribedUsers?.result?.data?.expires_at;
  const startDate = subscribedUsers?.result?.data?.start_time;
  const totalCount =
    (subscribedUsers?.result?.data?.over_subscribed_count ?? 0) +
    (subscribedUsers?.result?.data?.under_subscribed_count ?? 0) +
    (subscribedUsers?.result?.data?.soldout_count ?? 0);

  const tableHeaders = [
    {
      id: 'name',
      label: translate('tableHeader.name'),
      showColumn: true,
      initialWidth: 150
    },
    {
      id: 'kycUsername',
      label: translate('tableHeader.kycName'),
      showColumn: true,
      initialWidth: 160
    },
    {
      id: 'email',
      label: translate('tableHeader.email'),
      showColumn: true,
      initialWidth: 200
    },
    {
      id: 'subscriptionAmount',
      label: translate('tableHeader.subscriptionAmount'),
      showColumn: true,
      initialWidth: 200
    },
    {
      id: 'subscriptionDateTime',
      label: translate('tableHeader.subscriptionDateTime'),
      showColumn: true,
      initialWidth: 230
    },
    {
      id: 'opportunityStatus',
      label: translate('tableHeader.opportunityStatus'),
      showColumn: true,
      initialWidth: 280
    },
    {
      id: 'residencyStatus',
      label: translate('tableHeader.residencyStatus'),
      showColumn: true,
      initialWidth: 170
    },
    {
      id: 'kycStatus',
      label: translate('tableHeader.kycStatus'),
      showColumn: true,
      initialWidth: 150
    },
    {
      id: 'icAifStatus',
      label: translate('tableHeader.icAifStatus'),
      showColumn: true,
      initialWidth: 230
    }
  ];

  const subscribedUsersData = useMemo(
    () =>
      subscribedUsers?.result?.data?.opportunity_subscribed_user?.map(
        eachUser => ({
          id: eachUser.id,
          name: eachUser.name,
          kycUsername: eachUser.kyc_username,
          email: eachUser.email,
          residencyStatus:
            eachUser.residency_status === 'resident_indian' ? 'Indian' : 'NRI',
          kycStatus: eachUser.kyc_status,
          icAifStatus: eachUser.aif_status,
          subscriptionAmount: eachUser.amount,
          subscriptionDateTime: formatDateTime(eachUser.subscription_date),
          opportunityStatus: eachUser.opportunity_status
        })
      ) || [],
    [subscribedUsers?.result?.data]
  );

  const processTableData = (
    data: Record<string, string | number | boolean>
  ) => {
    return {
      name: (
        <div style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>
          {data.name || '-'}{' '}
        </div>
      ),
      email: (
        <div style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>
          {data.email || '-'}
        </div>
      ),
      kycUsername: (
        <div style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>
          {data.kycUsername || '-'}{' '}
        </div>
      ),
      residencyStatus: data.residencyStatus || '-',
      kycStatus: (
        <DefaultColorChip
          eachEntry={data.kycStatus}
          colorMapping={kycStatusColorMapping}
          labelValue={translateDynamic(`kycStatus.${data.kycStatus}`)}
          variant={'filled'}
        />
      ),
      icAifStatus: (
        <DefaultColorChip
          eachEntry={data.icAifStatus}
          colorMapping={icaifColorMapping}
          labelValue={translateDynamic(`icAifStatus.${data.icAifStatus}`)}
          variant={'filled'}
        />
      ),
      subscriptionAmount:
        data.opportunityStatus === 'soldout'
          ? '-'
          : formatToIndianRupee(Number(data.subscriptionAmount)) || '-',
      subscriptionDateTime: data.subscriptionDateTime || '-',
      opportunityStatus: (
        <DefaultColorChip
          eachEntry={data.opportunityStatus}
          colorMapping={opportunityStatusSubscriptionColorMapping}
          labelValue={translateDynamic(
            `opportunitySubscribedUsers.opportunityStatus.${data.opportunityStatus}`
          )}
          variant={
            data.opportunityStatus === 'over-subscribed' ? 'filled' : 'outlined'
          }
          minWidth={140}
        />
      )
    };
  };

  const onClickDownloadReport = () => {
    dispatch(updateDownloadSubscribedUsersDealId(dealId));
    dispatch(updateDownloadSubscribedUsersCompanyId(companyId));
  };

  const onClickNavigateButton = () => {
    navigate(`${RoutePath.VIEW_EARLY_ACCESS}/${dealId}/${companyId}`);
  };

  const handleLimitChange = useCallback((newLimit: number) => {
    setLimit(newLimit);
  }, []);

  const handleOffsetChange = useCallback((newOffset: number) => {
    setOffset(newOffset);
  }, []);

  const handleChangeOrder = useCallback(
    (newOrder: Order) => {
      setSortOrder(newOrder);
      dispatch(
        updateSubscribedUsersEarlyAccessValues({
          order: newOrder
        })
      );
    },
    [dispatch]
  );

  const handleSort = useCallback(
    (value: subscribedUsersEarlyAccessTableHeader) => {
      setSortBy(value);
      const sortByString =
        tableHeaderMapper[
          value.toString() as subscribedUsersEarlyAccessTableHeader
        ];
      dispatch(
        updateSubscribedUsersEarlyAccessValues({
          sortBy: sortByString
        })
      );
    },
    [dispatch]
  );

  const handleViewUser = (
    data: Record<string, string | number | boolean | Date | null>
  ) => {
    navigate(
      `${RoutePath.VIEW_USER}/${data.id ?? ''}?tab=${USERS_TABS.DEAL_INFO}`
    );
  };

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      getSubscribedUsers({
        dealId: dealId ?? '',
        searchTerm: encodeURIComponent(searchText),
        limit,
        offset,
        order: sortOrder,
        sortBy: sortBy
      });
    }, 500);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [searchText, sortBy, sortOrder, offset, limit]);

  const currentStatus =
    endDate && new Date(endDate) < new Date()
      ? OpportunityStatus.EXPIRED
      : status;

  return subscribedUsers ? (
    <div className="py-6 px-5">
      {/* Header section */}
      <div className="flex justify-between items-center text-[34px] font-normal">
        <div className="text-[34px] font-normal">
          {companyName
            ? translate('opportunitySubscribedUsers.header', { companyName })
            : translate('opportunities.viewEarlyAccess')}
          {status && (
            <span className="ml-2">
              <DefaultColorChip
                eachEntry={currentStatus}
                colorMapping={opportunityColorMapping}
                labelValue={translateDynamic(`dealStatus.${currentStatus}`)}
                variant={
                  currentStatus === OpportunityStatus.ACTIVE ||
                  currentStatus === OpportunityStatus.EXPIRED ||
                  currentStatus === 'Approved'
                    ? 'filled'
                    : 'outlined'
                }
              />
            </span>
          )}
        </div>
        <div className="flex flex-row gap-5 justify-end items-center">
          {totalCount > 0 && status !== OpportunityStatus.DRAFT ? (
            <Button
              variant="outlined"
              onClick={onClickDownloadReport}
              startIcon={
                !reportIsLoading && <FileDownload className="invisible" />
              }
              style={{
                color: 'black',
                border: '1px solid #212121',
                height: '36px',
                minWidth: '108px'
              }}
              className={`group py-3 px-4 w-[82px] h-[30px]  hover:!border-primary hover:shadow-sm transition-opacity duration-700 1.25xl:h-[42px] ${
                reportIsLoading ? '!border-primary' : ''
              }`}
              disabled={reportIsLoading}>
              {reportIsLoading ? (
                <div className="flex absolute top-1/2 left-2 items-center space-x-2 -translate-y-1/2">
                  <CircularProgress size="1rem" sx={{ color: 'primary' }} />
                  <span className="text-primary">
                    {translate('opportunities.overallSubscriptionCsv')}
                  </span>
                </div>
              ) : (
                <>
                  <FileDownload className="absolute top-1/2 left-2 mr-4 -translate-y-1/2 group-hover:fill-primary" />
                  <span className="text-black group-hover:text-primary">
                    {translate('opportunities.overallSubscriptionCsv')}
                  </span>
                </>
              )}
            </Button>
          ) : (
            <></>
          )}
          <CustomButton
            label={'Edit'}
            onClick={onClickNavigateButton}
            variant="contained"
            fullWidth={false}
            customClassname="max-h-[36px]"
            icon={<PencilEditIcon className="fill-white" />}
          />
        </div>
      </div>
      <div className="flex flex-row gap-2 mt-5">
        <Chip
          label={translate('startDate', {
            date: getFormattedDate(
              getDateFromUtcDateWithTimeZone(startDate ?? ''),
              'YYYY MMM DD, hh:mm A'
            )
          })}
          variant="filled"
          style={{
            fontSize: '16px',
            fontWeight: '400',
            borderRadius: '4px',
            backgroundColor: colors.gray01
          }}
        />
        <Chip
          label={translate('endDate', {
            date: getFormattedDate(
              getDateFromUtcDateWithTimeZone(endDate ?? ''),
              'YYYY MMM DD, hh:mm A'
            )
          })}
          variant="filled"
          style={{
            fontSize: '16px',
            fontWeight: '400',
            borderRadius: '4px',
            backgroundColor: colors.gray01
          }}
        />
      </div>

      {/* Analytics section */}
      {status !== OpportunityStatus.DRAFT ? (
        <div className="flex flex-wrap gap-3 mt-6 mb-2 mac1380:flex-nowrap">
          <CircularProgressChart
            total={Number(subscribedUsers?.result?.data?.round_size)}
            subscribed={Number(subscribedUsers?.result?.data?.total_amount)}
          />
          <SubscriptionStatusAnalytics
            underSubscribedSize={Number(
              subscribedUsers?.result?.data?.under_subscribed_count
            )}
            overSubscribedSize={Number(
              subscribedUsers?.result?.data?.over_subscribed_count
            )}
            soldOutSize={Number(subscribedUsers?.result?.data?.soldout_count)}
          />
        </div>
      ) : (
        <></>
      )}
      {totalCount <= 0 || status === OpportunityStatus.DRAFT ? (
        <div className="p-9 h-full">
          <DealListEmptyState
            text={`${
              status === OpportunityStatus.DRAFT
                ? translate('opportunitySubscribedUsers.emptyState.notLive')
                : translate(
                    'opportunitySubscribedUsers.emptyState.noSubscribers'
                  )
            }`}
            buttonLabel={translate(
              'opportunitySubscribedUsers.emptyState.editOpportunity'
            )}
            onClickNavigateButton={onClickNavigateButton}
          />
        </div>
      ) : (
        <>
          {/* Overall subscribed users list */}
          <div className="mt-5">
            <div className="pb-6 text-2xl font-medium leading-7">
              {translate('opportunities.overallSubscription')}
            </div>
          </div>
          <div className="flex gap-4 pb-4 w-[70%] ">
            <div className="flex flex-row w-[50%] xl:w-[40%] 2xl:w-[30%]">
              <PersistingSearchBar
                placeholder={translate('search.placeholder')}
                label={translate('search.label')}
                dispatchFn={updateSubscribedUsersEarlyAccessValues}
                defaultValue={''}
              />
            </div>
          </div>
          <Suspense fallback={<></>}>
            <div className="w-full">
              <MaterialReactTable
                tableHeaders={tableHeaders}
                tableData={subscribedUsersData}
                processTableData={processTableData}
                elevation={0}
                isLoading={isLoading || isFetching}
                page={Math.floor(offset / limit) ?? 0}
                rowsPerPage={limit ?? 0}
                handleLimitChange={handleLimitChange}
                handleOffsetChange={handleOffsetChange}
                autoResetPageIndex={false}
                order={sortOrder}
                handleChangeOrder={handleChangeOrder}
                handleOrderBy={handleSort}
                orderBy={sortBy}
                totalRows={subscribedUsers?.result.total_count ?? 0}
                isRowClickable={true}
                handleRowClick={handleViewUser}
                customHeightOffest={155}
                disableColumnActionMenu
              />
            </div>
          </Suspense>
        </>
      )}
    </div>
  ) : (
    <div className="flex flex-col p-9">
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={isLoading || isFetching}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default OverallSubscriptionStatus;
