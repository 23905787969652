import React, { FC } from 'react';
import { Chip } from '@mui/material';

import { colors } from '@constants/colors';
import { hexToRgba } from '@utils/common';

import { DefaultColorChipProps } from './types';

const DefaultColorChip: FC<DefaultColorChipProps> = ({
  eachEntry,
  colorMapping,
  labelValue,
  variant = 'filled',
  borderRadius = '4px',
  defaultColor = {
    backgroundColor: hexToRgba(colors.gray600, 0.1),
    textColor: colors.gray02
  },
  minWidth = 120
}) => {
  const entryColor = colorMapping[String(eachEntry)] || defaultColor;

  const { backgroundColor, textColor } = entryColor
    ? {
        backgroundColor: entryColor.backgroundColor,
        textColor: entryColor.textColor
      }
    : {
        backgroundColor: colors.gray,
        textColor: colors.gray11
      };

  return (
    <Chip
      label={labelValue}
      size="medium"
      variant={variant}
      style={{
        color: textColor,
        backgroundColor: variant === 'filled' ? backgroundColor : 'transparent',
        borderColor: variant === 'outlined' ? backgroundColor : 'transparent',
        borderRadius,
        minWidth,
        fontWeight: 450
      }}
    />
  );
};

export default DefaultColorChip;
