import { ApiTags } from '@constants/common';
import { Order } from '@components/custom-table/types';
import {
  DownloadReportResponseType,
  PollResponse
} from '@modules/deals/deal-analytics/types';
import { GenericApiResponse } from '@type/general';
import restApi from '@services/api';

import {
  GetWishlistResponse,
  CreateWishlistResponse,
  CreateWishlistRequest,
  UpdateWishlistResponse,
  UpdateWishlistRequest,
  WishlistResponse,
  WishlistedUsersResponse
} from './types';
import { tableHeaderMapper } from './constants';

const GetWishListApi = restApi.injectEndpoints({
  endpoints: builder => ({
    getWishlist: builder.query<WishlistResponse, { dealId?: string }>({
      query: payload => ({
        url: `${process.env.REACT_APP_BASE_API_VERSION}/support/deal/${payload.dealId}`,
        method: 'GET'
      }),
      providesTags: [ApiTags.opportunity],
      transformResponse: (response: GetWishlistResponse) => response.result
    }),

    // API: overall WishListed Users Data
    getWishListedUsers: builder.query<
      WishlistedUsersResponse,
      {
        dealId?: string;
        offset?: number;
        limit?: number;
        order?: Order;
        sortBy?: string;
        searchTerm?: string;
      }
    >({
      query: payload => ({
        url: `${
          process.env.REACT_APP_BASE_API_VERSION
        }/support/wishlist/users/${payload.dealId}?${
          payload.searchTerm ? `&search=${payload.searchTerm}` : ''
        }&limit=${payload.limit}&offset=${payload.offset}&sort=${
          tableHeaderMapper[payload.sortBy as keyof typeof tableHeaderMapper] ??
          'name'
        }&order=${payload.order}`,
        method: 'GET'
      })
    }),

    //Report Generation API: polling
    generateWishListedUsersReportPoll: builder.query<
      GenericApiResponse<PollResponse>,
      { jobId: string }
    >({
      query: payload => ({
        url: `${process.env.REACT_APP_BASE_API_VERSION}/support/wishlist/users/export/poll/${payload.jobId}`,
        method: 'GET'
      }),
      transformResponse: (response: GenericApiResponse<PollResponse>) =>
        response
    }),

    //Report Generation API: report format
    wishListedUsersReportGeneration: builder.mutation<
      DownloadReportResponseType,
      { dealId: string; format: string }
    >({
      query: payload => ({
        url: `${process.env.REACT_APP_BASE_API_VERSION}/support/wishlist/users/export/${payload.dealId}`,
        method: 'POST',
        body: { format: payload.format }
      }),
      transformResponse: (response: DownloadReportResponseType) => response
    }),

    createWishlist: builder.mutation<
      CreateWishlistResponse,
      CreateWishlistRequest
    >({
      query: payload => ({
        url: `${process.env.REACT_APP_BASE_API_VERSION}/support/deal`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: [ApiTags.opportunityList, ApiTags.opportunity],
      transformResponse: (response: CreateWishlistResponse) => response
    }),
    updateWishlist: builder.mutation<
      UpdateWishlistResponse,
      UpdateWishlistRequest
    >({
      query: payload => ({
        url: `${process.env.REACT_APP_BASE_API_VERSION}/support/deal/${payload.id}`,
        method: 'PUT',
        body: payload
      }),
      invalidatesTags: [ApiTags.opportunityList, ApiTags.opportunity],
      transformResponse: (response: CreateWishlistResponse) => response
    })
  })
});

export const {
  useCreateWishlistMutation,
  useUpdateWishlistMutation,
  useLazyGetWishlistQuery,
  useLazyGetWishListedUsersQuery,
  useWishListedUsersReportGenerationMutation,
  useLazyGenerateWishListedUsersReportPollQuery
} = GetWishListApi;
