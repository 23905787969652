import { ApiTags } from '@constants/common';
import { Order } from '@components/custom-table/types';
import {
  DownloadReportResponseType,
  PollResponse
} from '@modules/deals/deal-analytics/types';
import { GenericApiResponse } from '@type/general';
import restApi from '@services/api';

import {
  CreateEarlyAccessRequest,
  CreateEarlyAccessResponse,
  EarlyAccess,
  GetEarlyAccessResponse,
  SubscribedUsersResponse,
  UpdateEarlyAccessRequest,
  UpdateEarlyAccessResponse
} from './types';
import { tableHeaderMapper } from './constants';

const EarlyAccessApi = restApi.injectEndpoints({
  endpoints: builder => ({
    getEarlyAccess: builder.query<EarlyAccess, { id?: string }>({
      query: payload => ({
        url: `${process.env.REACT_APP_BASE_API_VERSION}/support/deal/${payload.id}`,
        method: 'GET'
      }),
      providesTags: [ApiTags.earlyAccess],
      transformResponse: (response: GetEarlyAccessResponse) => response.result
    }),

    // API: overall Subscribed Users Data in early Access
    getSubscribedUsers: builder.query<
      SubscribedUsersResponse,
      {
        dealId?: string;
        offset?: number;
        limit?: number;
        order?: Order;
        sortBy?: string;
        searchTerm?: string;
      }
    >({
      query: payload => ({
        url: `${
          process.env.REACT_APP_BASE_API_VERSION
        }/support/opportunities/subscribed-users/${payload.dealId}?${
          payload.searchTerm ? `&search=${payload.searchTerm}` : ''
        }&limit=${payload.limit}&offset=${payload.offset}&sort=${
          tableHeaderMapper[payload.sortBy as keyof typeof tableHeaderMapper] ??
          'name'
        }&order=${payload.order}`,
        method: 'GET'
      })
    }),

    //Report Generation API: polling
    generateSubscribedReportPoll: builder.query<
      GenericApiResponse<PollResponse>,
      { jobId: string }
    >({
      query: payload => ({
        url: `${process.env.REACT_APP_BASE_API_VERSION}/support/opportunities/subscribed-users/export/poll/${payload.jobId}`,
        method: 'GET'
      }),
      transformResponse: (response: GenericApiResponse<PollResponse>) =>
        response
    }),

    //Report Generation API: report format
    subscribedUsersReportGeneration: builder.mutation<
      DownloadReportResponseType,
      { dealId: string; format: string }
    >({
      query: payload => ({
        url: `${process.env.REACT_APP_BASE_API_VERSION}/support/opportunities/subscribed-users/export/${payload.dealId}`,
        method: 'POST',
        body: { format: payload.format }
      }),
      transformResponse: (response: DownloadReportResponseType) => response
    }),
    createEarlyAccess: builder.mutation<
      CreateEarlyAccessResponse,
      CreateEarlyAccessRequest
    >({
      query: payload => ({
        url: `${process.env.REACT_APP_BASE_API_VERSION}/support/deal`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: [ApiTags.earlyAccess],
      transformResponse: (response: CreateEarlyAccessResponse) => response
    }),
    updateEarlyAccess: builder.mutation<
      UpdateEarlyAccessResponse,
      UpdateEarlyAccessRequest
    >({
      query: payload => ({
        url: `${process.env.REACT_APP_BASE_API_VERSION}/support/deal/${payload.id}`,
        method: 'PUT',
        body: payload
      }),
      invalidatesTags: [ApiTags.earlyAccess],
      transformResponse: (response: UpdateEarlyAccessResponse) => response
    })
  })
});

export const {
  useLazyGetEarlyAccessQuery,
  useCreateEarlyAccessMutation,
  useUpdateEarlyAccessMutation,
  useLazyGetSubscribedUsersQuery,
  useSubscribedUsersReportGenerationMutation,
  useLazyGenerateSubscribedReportPollQuery
} = EarlyAccessApi;
