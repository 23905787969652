import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormReturn,
  UseFormSetValue
} from 'react-hook-form';

import { DrawDownStateType, OnboardingStatus } from '@constants/common';
import { OptionsLOVType } from '@components/auto-complete/types';
import { AIF } from '@modules/deals/create-deal/types';

import {
  DealsInvestedType,
  DocumentDetailsType,
  KycDetailsData,
  UserInfoFormValues
} from '../types';

export type UserDetailsHeaderPropsType = {
  activeStep: number;
  steps: string[];
  handleStep: (step: number) => void;
  name: string;
  getValues: UseFormGetValues<UserInfoFormValues>;
  isLoading: boolean;
  profileImageUrl: string;
  onboardedState?: OnboardingStatus;
  invitedBy: string;
};

export type UserInfoFormPropsType = {
  handleSubmit: UseFormHandleSubmit<UserInfoFormValues, undefined>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<UserInfoFormValues, any>;
  errors: FieldErrors<UserInfoFormValues>;
  clearErrors: UseFormClearErrors<UserInfoFormValues>;
  setValue: UseFormSetValue<UserInfoFormValues>;
  getValues: UseFormGetValues<UserInfoFormValues>;
  enableEdit?: boolean;
  handleNextStep: () => void;
  editableSection: string;
  onClickEditButton: (section: string) => void;
  deleteClickedFile: string;
  handleFileDeleteClick: (sectionId: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  methods: UseFormReturn<UserInfoFormValues, any, undefined>;
  onClickCancelButton: () => void;
  aifList: AIF[] | undefined;
};

export type KycDetailsFormPropsType = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<UserInfoFormValues, any>;
  handleNextStep: () => void;
  getValues: UseFormGetValues<UserInfoFormValues>;
  clearErrors: UseFormClearErrors<UserInfoFormValues>;
  setValue: UseFormSetValue<UserInfoFormValues>;
  errors: FieldErrors<UserInfoFormValues>;
  enableEdit?: boolean;
  countryListOptions: OptionsLOVType[];
  handleAddNewSection: (section: string) => void;
  addedSections: string[];
  editableSection: string;
  onClickEditButton: (section: string) => void;
  deleteClickedFile: string;
  handleFileDeleteClick: (sectionId: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  methods: UseFormReturn<UserInfoFormValues, any, undefined>;
  onClickCancelButton: () => void;
  kycDetails?: KycDetailsData;
  userId?: string;
  dealDocExist: boolean;
};

export type DealDocumentsFormPropsType = {
  getValues: UseFormGetValues<UserInfoFormValues>;
  dealsInvested: DealsInvestedType[];
  userId?: string;
  userName: string;
  ppmAccepted?: { [id: string]: boolean };
  drawDownMailSent?: DrawDownStateType;
};

export type AifDocumentsFormPropsType = {
  getValues: UseFormGetValues<UserInfoFormValues>;
  dealDocs: DocumentDetailsType[];
  userId?: string;
  userName: string;
  ppmAccepted?: { [id: string]: boolean };
  drawDownMailSent?: DrawDownStateType;
  enableRollingFund: string;
};

export type UserSettingsPropsType = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<UserInfoFormValues, any>;
  enableEdit?: boolean;
  editableSection: string;
  onClickCancelButton: () => void;
  onClickEditButton: (section: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  methods: UseFormReturn<UserInfoFormValues, any, undefined>;
  handleSubmit: UseFormHandleSubmit<UserInfoFormValues, undefined>;
};

export type regenerateType = {
  regenerate: boolean;
  aifDocId: string;
  dealId?: string;
  docName?: string;
};

export enum USERS_TABS {
  'USER_INFO' = 'user_info',
  'KYC' = 'kyc',
  'DEMAT' = 'demat',
  'AIF_DOCS' = 'aif_docs',
  'DEAL_INFO' = 'deal_info',
  'USER_SETTINGS' = 'user_settings'
}

export const stepMapUrl: {
  [key: number]: USERS_TABS;
} = {
  0: USERS_TABS.USER_INFO,
  1: USERS_TABS.KYC,
  2: USERS_TABS.DEMAT,
  3: USERS_TABS.AIF_DOCS,
  4: USERS_TABS.DEAL_INFO,
  5: USERS_TABS.USER_SETTINGS
};
