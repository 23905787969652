import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { handleDownloadFile } from '@utils/common';
import { ToastTypes } from '@type/toast';
import { translate } from '@utils/locale';
import { API_RESPONSE_STATUS } from '@constants/common';
import { RootState } from '@store/reducers';
import { useAppDispatch } from '@store/store';
import { showGenericErrorToast } from '@utils/error';
import {
  useLazyGenerateWishListedUsersReportPollQuery,
  useWishListedUsersReportGenerationMutation
} from '@modules/opportunities/create-wishlist/api';
import {
  updateDownloadWishlistedUsersDealId,
  updateDownloadWishlistedUsersIsLoading
} from '@reducers/downloadWishlistedUsersReport';
import displayToast from '@components/toast/customToast';

const DownloadWishlistedUserReport = () => {
  const dispatch = useAppDispatch();
  const { dealId } = useSelector(
    (state: RootState) => state.rootReducer.downloadWishlistedUsersReport
  );

  const [pollingInterval, setPollingInterval] = useState<number>(3000);
  const [jobID, setJobId] = useState('');

  const [
    getAnalyticsReport,
    { data: analyticsReportResult, error: analyticsReportError }
  ] = useWishListedUsersReportGenerationMutation();
  const [generateReportPoll, { data: pollStatus, error: pollStatusError }] =
    useLazyGenerateWishListedUsersReportPollQuery();

  const onGeneratePoll = (jobId: string) => {
    generateReportPoll({
      jobId: jobId
    });
  };

  const updateIsLoading = (isLoading: boolean) => {
    dispatch(updateDownloadWishlistedUsersIsLoading(isLoading));
  };

  useEffect(() => {
    if (dealId) {
      getAnalyticsReport({
        dealId: dealId,
        format: 'excel'
      });
      updateIsLoading(true);
      setPollingInterval(10000);
    }
  }, [dealId]);

  useEffect(() => {
    if (analyticsReportResult?.status === API_RESPONSE_STATUS.OK) {
      onGeneratePoll(analyticsReportResult.result.job_id);
      setJobId(analyticsReportResult.result.job_id);
    } else {
      updateIsLoading(false);
    }
  }, [analyticsReportResult]);

  useEffect(() => {
    if (analyticsReportError || pollStatusError) {
      dispatch(updateDownloadWishlistedUsersDealId(''));
      updateIsLoading(false);
      setPollingInterval(0);
      setJobId('');
      displayToast(ToastTypes.ERROR, translate('errorFallbackMessage'));
    }
  }, [analyticsReportError, pollStatusError]);

  useEffect(() => {
    if (pollStatus && analyticsReportResult) {
      if (pollStatus?.result?.status === 'success') {
        displayToast(ToastTypes.SUCCESS, translate('report.success'));
        handleDownloadFile(pollStatus.result.signed_url);
        dispatch(updateDownloadWishlistedUsersDealId(''));
        updateIsLoading(false);
        setPollingInterval(10000);
        setJobId('');
      } else if (pollStatus?.result.status !== 'processing') {
        showGenericErrorToast(pollStatus.error);
        dispatch(updateDownloadWishlistedUsersDealId(''));
        updateIsLoading(false);
        setPollingInterval(0);
        setJobId('');
      }
    }
  }, [pollStatus]);

  useEffect(() => {
    let interval: NodeJS.Timer;
    if (jobID && pollingInterval) {
      interval = setInterval(() => onGeneratePoll(jobID), pollingInterval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [jobID, pollingInterval]);

  return {};
};

export default DownloadWishlistedUserReport;
