import * as React from 'react';

import { formatNumber } from '@utils/common';
import { AnalyticsGraphCard } from '@components';
import { colors } from '@constants/colors';
import { translate } from '@utils/locale';

const SubscriptionStatusAnalytics = ({
  underSubscribedSize,
  overSubscribedSize,
  soldOutSize
}: {
  underSubscribedSize: number;
  overSubscribedSize: number;
  soldOutSize: number;
}) => {
  const subscribedSummaryData = [
    [
      {
        value: parseInt(String(underSubscribedSize)),
        label: translate(
          'opportunitySubscribedUsers.analytics.underSubscribedLabel'
        ),
        color: colors.steelBlue
      },
      {
        value: parseInt(String(overSubscribedSize)),
        label: translate(
          'opportunitySubscribedUsers.analytics.overSubscribedLabel'
        ),
        color: colors.seaGreen
      },

      {
        value: parseInt(String(soldOutSize)),
        label: translate('opportunitySubscribedUsers.analytics.soldOutLabel'),
        color: colors.ivoryCream
      }
    ]
  ];

  return (
    <AnalyticsGraphCard
      data={subscribedSummaryData}
      title={translate(
        'opportunitySubscribedUsers.analytics.subscriptionCountTitle'
      )}
      totalText={translate(
        'opportunitySubscribedUsers.analytics.totalSubscribers'
      )}
      totalValue={underSubscribedSize + overSubscribedSize + soldOutSize}
      valueFormatter={formatNumber}
      cardName="subscriptionStatus"
    />
  );
};

export default SubscriptionStatusAnalytics;
