import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dealId: '',
  companyId: '',
  isLoading: false
};

export const downloadSubscribedUsersReportSlice = createSlice({
  name: 'userAuth',
  initialState,
  reducers: {
    updateDownloadSubscribedUsersDealId: (state, { payload }) => {
      state.dealId = payload;
    },
    updateDownloadSubscribedUsersCompanyId: (state, { payload }) => {
      state.companyId = payload;
    },
    updateDownloadSubscribedUsersIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    }
  }
});

export const {
  updateDownloadSubscribedUsersDealId,
  updateDownloadSubscribedUsersCompanyId,
  updateDownloadSubscribedUsersIsLoading
} = downloadSubscribedUsersReportSlice.actions;

export default downloadSubscribedUsersReportSlice.reducer;
