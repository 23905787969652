import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dealId: '',
  companyId: '',
  isLoading: false
};

export const downloadWishlistedUsersReportSlice = createSlice({
  name: 'userAuth',
  initialState,
  reducers: {
    updateDownloadWishlistedUsersDealId: (state, { payload }) => {
      state.dealId = payload;
    },
    updateDownloadWishlistedUsersCompanyId: (state, { payload }) => {
      state.companyId = payload;
    },
    updateDownloadWishlistedUsersIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    }
  }
});

export const {
  updateDownloadWishlistedUsersDealId,
  updateDownloadWishlistedUsersCompanyId,
  updateDownloadWishlistedUsersIsLoading
} = downloadWishlistedUsersReportSlice.actions;

export default downloadWishlistedUsersReportSlice.reducer;
