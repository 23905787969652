import { lazy } from 'react';

import AutocompleteDropdown from './auto-complete/AutoCompleteDropdwon';
import ActionButton from './action-button/ActionButton';
import AddButton from './add-button/AddButton';
import Breadcrumbs from './breadcrumbs/Breadcrumbs';
import Button from './button/Button';
import ColorPicker from './color-picker/ColorPicker';
import CustomButton from './custom-button/CustomButton';
import CustomDropDown from './custom-drop-down/CustomDropDown';
import CustomModal from './custom-modal/CustomModal';
import CustomTable from './custom-table/CustomTable';
import CustomTextBox from './custom-text-box/CustomTextBox';
import DatePicker from './date-picker/DatePicker';
import DropDown from './custom-drop-down/CustomDropDown';
import DualButton from './dual-button/DualButton';
import ErrorBoundary from './error-fallback/ErrorFallback';
import FileDropZone from './file-drop-zone/FileDropZone';
import FormDualButton from './form-dual-button/FormDualButton';
import HorizontalStepper from './stepper/Stepper';
import NavBar from './nav-bar/NavBar';
import RhfDropdown from './rhf-dropdown/RhfDropdown';
import RhfTextField from './rhf-text-field/RhfTextField';
// import RichTextEditor from './rich-text-editor/RichTextEditor';
import SearchBar from './search-bar/SearchBar';
import TopBar from './top-bar/TopBar';
import CustomSwitch from './switch-component/CustomSwitch';
import StatusChangeModal from './status-change-modal/StatusChangeModal';
import EllipsisTooltipText from './ellipsis-tooltip-text/EllipsisTooltipText';
import ImagePreviewCard from './image-preview-card/ImagePreviewCard';
import Table from './table/Table';
import TextFieldWithPattern from './text-field-with-pattern/TextFieldWithPattern';
import RhfTextFieldWithPattern from './rfh-text-field-with-pattern/RhfTextFieldWithPattern';
import OverflowTooltipTextBox from './tooltip-textbox/OverFlowTooltiptextBox';
import CustomTab from './tabs/Tabs';
import RhfTextFieldWithSearch from './rhf-textField-with-search/RhfTextFieldWithSearch';
import SelectOptionWithIconModal from './select-option-with-icon-modal/SelectOptionWithIconModal';
import PersistingSearchBar from './persisting-search-bar/PersistingSearchBar';
import AnalyticsGraphCard from './analytics-graph-card/AnalyticsGraphCard';
import AnalyticsCard from './analytics-card/AnalyticsCard';
import PageLoader from './page-loader/PageLoader';
import ColorChip from './color-chip/ColorChip';

const MaterialReactTable = lazy(() => import('./table/MaterialReactTable'));
const ImageCropper = lazy(() => import('./image-cropper/ImageCropper'));
const RichTextEditor = lazy(() => import('./rich-text-editor/RichTextEditor'));

export {
  AutocompleteDropdown,
  AnalyticsGraphCard,
  AnalyticsCard,
  ActionButton,
  AddButton,
  Breadcrumbs,
  Button,
  ColorPicker,
  CustomButton,
  CustomDropDown,
  CustomModal,
  CustomTable,
  CustomTextBox,
  CustomSwitch,
  CustomTab,
  DatePicker,
  DropDown,
  DualButton,
  ErrorBoundary,
  EllipsisTooltipText,
  FileDropZone,
  FormDualButton,
  HorizontalStepper,
  ImageCropper,
  ImagePreviewCard,
  MaterialReactTable,
  NavBar,
  OverflowTooltipTextBox,
  PersistingSearchBar,
  RhfDropdown,
  RhfTextField,
  RichTextEditor,
  RhfTextFieldWithSearch,
  RhfTextFieldWithPattern,
  SearchBar,
  StatusChangeModal,
  SelectOptionWithIconModal,
  TopBar,
  Table,
  TextFieldWithPattern,
  PageLoader,
  ColorChip
};
