import { OpportunityTypes } from '@constants/common';

import { translate } from '@utils/locale';
import { hexToRgba } from '@utils/common';
import { colors } from '@constants/colors';

import { CreateEarlyAccessFormFieldValues } from './types';

export const FIELD_TO_VALUE_MAPPER = {
  companyId: 'Company',
  opportunityType: 'Opportunity Type',
  earlyAccessType: 'Early Access Type',
  dealMode: 'Deal Mode',
  valuationType: 'Valuation Type',
  roundId: 'Round',
  allocation: 'Allocation',
  minInvestment: 'Minimum Investment',
  maximumInvestment: 'Maximum Investment',
  commitInsight: 'Commit Insight',
  highlights: 'Highlights',

  allocationDealTerm: 'Allocation Deal Term',
  totalRoundSizeDealTerm: 'Total Round Size Deal Term',
  minInvestDealTerm: 'Minimum Investment Deal Term',
  maxInvestDealTerm: 'Maximum Investment Deal Term',
  convertibleTermsDealTerm: 'Convertible Terms Deal Term',
  preValDealTerm: 'Current Valuation Deal Term',
  roundNameDealTer: 'Round Name Deal Term',

  documents: 'Documents',
  content: 'Deal Content',
  metrics: 'Deal Metrics',
  faq: 'FAQ',
  news: 'News',
  quickLinks: 'Quick Links',

  coverImage: 'Cover Image',
  backgroundColor: 'Background Colour',

  investorTiles: translate('opportunities.configuration.investorTiles'),
  committerCount: translate('opportunities.configuration.committerCount'),
  infinyteInvestorCount: translate(
    'opportunities.configuration.infinyteInvestorCount'
  ),
  isSoldOut: translate('opportunities.configuration.isSoldOut'),
  cardLabel: translate('opportunities.configuration.cardLabel'),
  cardLabelText: 'Card Label Text',
  overSubscribedCtaText: translate(
    'opportunities.configuration.overSubscribedCtaText'
  ),
  underSubscribedCtaText: translate(
    'opportunities.configuration.underSubscribedCtaText'
  ),
  progressBar: translate('opportunities.configuration.progressBar'),
  progressBarTextMode: 'Progress Bar Text ',
  startDate: 'Start Date',
  endDate: 'End Date'
} as { [x: string]: string };

export const FIELD_TO_TAB_MAPPER = {
  0: ['companyId'],
  1: [],
  2: [
    'opportunityType',
    'earlyAccessType',
    'dealMode',
    'valuationType',
    'roundId',
    'allocation',
    'minInvestment',
    'maximumInvestment',
    'highlights',

    'allocationDealTerm',
    'totalRoundSizeDealTerm',
    'minInvestDealTerm',
    'maxInvestDealTerm',
    'convertibleTermsDealTerm',
    'preValDealTerm',
    'roundNameDealTerm',
    'highlights',
    'metrics'
  ],
  3: ['documents', 'content', 'faq', 'news', 'quickLinks'],
  4: [
    'investorTiles',
    'committerCount',
    'infinyteInvestorCount',
    'isSoldOut',
    'cardLabel',
    'cardLabelText',
    'progressBar',
    'progressBarTextMode',
    'subscriptionThreshold',
    'underSubscriptionCtaText',
    'overSubscriptionCtaText',
    'startDate',
    'endDate'
  ]
} as { [x: string]: string[] };

export const createEarlyAccessDefaultValues: CreateEarlyAccessFormFieldValues =
  {
    id: '',
    status: '',
    dealName: '',
    isPublish: false,
    dealCode: '',
    isDraft: true,
    versionNumber: 0,

    companyId: '',
    earlyAccessType: '',
    startDate: null,
    endDate: null,
    opportunityType: OpportunityTypes.WAITLIST,
    dealMode: '',
    allocation: '',
    minInvestment: '',
    maximumInvestment: '',
    commitInsight: '',

    valuationType: '',
    roundId: '',
    highlights: [],
    coverImage: { file: undefined, path: '' },
    // backgroundColor: '',
    documents: [
      {
        id: '',
        title: '',
        description: '',
        document: { file: undefined, path: '' }
      }
    ],
    metrics: [
      {
        id: '',
        title: '',
        metrics: [
          {
            subheading: '',
            data: ''
          }
        ]
      }
    ],
    faq: [
      {
        id: '',
        title: '',
        shouldDisplay: false,
        referenceId: 0,
        questions: [
          {
            id: '',
            question: '',
            answer: ''
          }
        ]
      }
    ],

    news: [
      {
        id: '',
        url: '',
        order: 0
      }
    ],

    quickLinks: [
      {
        id: '',
        quickLinkEntryId: '',
        url: ''
      }
    ],
    content: [
      {
        id: '',
        title: '',
        description: '',
        url: ''
      }
    ],

    allocationId: '',
    allocationDealTerm: '',
    totalRoundSizeId: '',
    totalRoundSizeDealTerm: '',
    minInvestId: '',
    minInvestDealTerm: '',
    preMoneyValuationId: '',
    preValDealTerm: '',
    roundNameId: '',
    roundNameDealTerm: '',
    maxInvestId: '',
    maxInvestDealTerm: '',
    convertibleTermsId: '',
    convertibleTermsDealTerm: '',
    progressBar: false,
    progressBarText: false,
    progressBarTextMode: '',
    investorTiles: true,
    committerCount: false,
    infinyteInvestorCount: true,
    isSoldOut: false,
    cardLabel: false,
    cardLabelText: '',
    bypassScreening: false,
    totalSubscribedAmount: '0',
    subscriptionThreshold: '',
    underSubscribedCtaText: '',
    overSubscribedCtaText: '',
    selectedSoldOut: 'live',
    selectedAdditionalInfo: 'hide'
  };

export const tableHeaderMapper = {
  name: 'name',
  kycUsername: 'kyc_username',
  email: 'email',
  residencyStatus: 'residency_status',
  kycStatus: 'kyc_status',
  icAifStatus: 'aif_status',
  subscriptionAmount: 'amount',
  subscriptionDateTime: 'subscription_date',
  opportunityStatus: 'opportunity_status'
};

export enum DealAnalyticsTableHeader {
  NAME = 'name',
  EMAIL = 'email',
  PHONE = 'phone',
  INVESTOR_ROLE = 'investorRole',
  RESIDENT_STATUS = 'residentStatus',
  USER_STATUS = 'userStatus',
  USER_DETAILED_STATUS = 'userDetailedStatus',
  INVESTMENT_STATUS = 'investmentStatus',
  BANK_TYPE = 'bankAccountType',
  EXPECTED_COMMIT = 'expectedCommit',
  ACTUAL_COMMIT = 'actualCommit',
  AIF = 'aif',
  EXPECTED_INVESTMENT = 'expectedInvestment',
  INVESTED_AMOUNT = 'investedAmount',
  PENDING_INVESTMENT = 'pendingInvestment',
  EXISTING_INVESTOR = 'existingInvestor',
  DEAL_COMMITTED_DATE = 'dealCommittedDate',
  DEAL_INVITED_DATE = 'dealInvitedDate',
  ACTIONS = 'actions',
  DEMAT = 'dematDetails'
}

export enum AnalyticsUserStatus {
  YET_TO_SIGNUP = 'yetToSignUp',
  SIGNED_UP = 'signedUp',
  KYC_COMPLETED = 'kycCompleted',
  AIF_ONBOARDED = 'aifOnboarded'
}

export enum InvestmentStatus {
  ICF_SIGNED = 'icfSigned',
  COMMITTED = 'committed',
  NO_ENGAGEMENT = 'noEngagement',
  PAYMENT_COMPLETED = 'paymentCompleted',
  DROPPED_OFF = 'droppedOff'
}

export enum InvestorRole {
  FOUNDER_LED = 'founderLed',
  SYNDICATE_LED = 'syndicateLed',
  LP = 'lp',
  INVESTOR = 'investor'
}

export enum ActionTypes {
  inviteInvestors = 'inviteInvestor',
  editDeal = 'editDeal',
  changeState = 'changeState',
  report = 'report',
  download = 'download'
}
export enum subscribedUsersEarlyAccessTableHeader {
  NAME = 'name',
  EMAIL = 'email',
  KYC_USERNAME = 'kycUsername',
  RESIDENCY_STATUS = 'residencyStatus',
  KYC_STATUS = 'kycStatus',
  IC_AIF_STATUS = 'icAifStatus',
  SUBSCRIPTION_AMOUNT = 'subscriptionAmount',
  SUBSCRIPTION_DATE_TIME = 'subscriptionDateTime',
  OPPORTUNITY_STATUS = 'opportunityStatus'
}

export const opportunityStatusSubscriptionColorMapping = {
  'over-subscribed': {
    backgroundColor: hexToRgba(colors.seaGreen, 0.1),
    textColor: colors.seaGreen
  },
  'under-subscribed': {
    backgroundColor: colors.steelBlue,
    textColor: colors.steelBlue
  },
  soldout: {
    backgroundColor: colors.gray90,
    textColor: colors.gray90
  }
};
